import { CustomLoader } from "./components/CustomLoader/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { hideSimpleModalAction } from "./redux/actions";
import React, { Suspense, useEffect } from "react";
import { CustomModal } from "./components/CustomModal/CustomModal";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import history from "./history";
import { ErrorPage } from "./view/404.jsx";
import { Navigate } from "react-router";
import MainPage from "./view/MainPage";
import { NewPasswordPage } from "./view/NewPasswordPage/NewPasswordPage";
import PrettyUrlPage from "./view/PrettyUrlPage.js";

function App() {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.helpers.loader);
  const simpleModal = useSelector((state) => state.helpers.simpleModal);
  const favicon = useSelector((state) => state.profile.favicon);
  const space = useSelector((state) => state.profile.space);

  const handleClose = () => {
    dispatch(hideSimpleModalAction());
  };

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = favicon;
    document.title = space?.name ?? "";
  }, [favicon, space?.name]);

  return (
    <Suspense fallback={<CustomLoader />}>
      {loader && <CustomLoader />}
      {simpleModal.isModal && (
        <div className="error-modal">
          <CustomModal
            title={simpleModal.title}
            close={handleClose}
            isCancelBtn={false}
            submitBtn="OK"
            submit={handleClose}
          >
            <div className="error-modal__text">{simpleModal.text}</div>
          </CustomModal>
        </div>
      )}

      <BrowserRouter history={history}>
        <Routes>
          <Route path="/" element={<Navigate to="/showcase" replace />} />
          <Route path="/showcase/:id" element={<MainPage />} />
          <Route path="/auth/verify/*" element={<NewPasswordPage />} />
          <Route path="/404" element={<ErrorPage />} />
          <Route path="*" element={<PrettyUrlPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
